<template>
    <div>
        <v-app-bar app class="app-bar" dense :flat="!scrollTop" :color="scrollTop ? 'red darken-2' : ''">
            <v-icon v-if="$vuetify.breakpoint.smAndUp"
                color="white"
                class="pb-6"
                @click.stop="scrollToTop"
            >mdi-home</v-icon>
            <v-icon v-else
                color="white"
                class="pb-6"
                @click.stop="primaryDrawer.model = !primaryDrawer.model"
            >mdi-menu</v-icon>
            <v-spacer />
            <div class="app-bar-item" @click="demonstracao">DEMONSTRAÇÃO</div>
            <div class="app-bar-item" @click="sendWhatsapp">CADASTRAR</div>
            <div class="app-bar-item" @click="login">LOGIN</div>
        </v-app-bar>

        <v-navigation-drawer v-model="primaryDrawer.model" :temporary="true" app overflow class="lateral-menu" width="80%">
            <v-list dense>
                <v-divider></v-divider>

                <v-list-item style="margin-left: 5px" @click="demonstracao">
                    <v-list-item-content>
                        <v-list-item-title>DEMONSTRAÇÃO</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item style="margin-left: 5px" @click="sendWhatsapp">
                    <v-list-item-content>
                        <v-list-item-title>CADASTRAR MINHA EMPRESA</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item style="margin-left: 5px" @click="login">
                    <v-list-item-content>
                        <v-list-item-title>LOGIN</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'Menu',

    data: () => ({
        scrollTop: false,
        primaryDrawer: {
            model: null,
        },
    }),

    mounted() {
        window.onscroll = () => this.changeColor();
    },

    methods: {
        // cadastrar() {
        //     window.open('https://admin.zapermenu.com.br/register', '_blank');
        // },

        demonstracao() {
            window.open('https://app.zapermenu.com.br/modelo-restaurante', '_blank');
        },

        login() {
            window.open('https://admin.zapermenu.com.br/login', '_blank');
        },

        sendWhatsapp() {
            const msg = 'Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20ZaperMenu';
            window.open(`https://api.whatsapp.com/send?phone=5549988951210&text=${msg}`);
        },

        changeColor() {
            this.scrollTop = (
                document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100
            );
        },

        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
};
</script>

<style scoped>
.app-bar {
    z-index: 120 !important;
    background-color: rgb(0 0 0 / 0%) !important;
}

.app-bar-item {
    padding: 0 12px 25px 12px;
    cursor: pointer;
    color: white;
}

.lateral-menu {
    padding-top: 60px;
    /* background-color: black LOGIN!important; */
}

.v-list-item__title {
    letter-spacing: 1px;
    font-size: 16px !important;
}

.v-text-field > .v-input__control > .v-input__slot {
    border-style: unset !important;
}
</style>
